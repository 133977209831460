<template>
  <div class="seat-timer" @click="$emit('avatarClick')">
    <!-- <div class="seat-timer-inner">{{ count }}</div> -->
    <!-- <div class="spark"></div> -->
    <div class="slice1 slice-wrap"></div>
    <div class="slice2 slice-wrap"></div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Timer",
  computed: {
    ...mapState(["user"]),
    ...mapGetters({
      getActiveRoomState: "service/getActiveRoomState",
    }),
    // getClasses() {
    //   const classes = [];

    //   const index = this.$parent.getComputedIndex;
    //   if ([0, 1, 2, 8].includes(index)) classes.push("right");

    //   return classes;
    // },
  },
  data() {
    return {
      count: 10,
      interval: -1,
    };
  },
  methods: {
    clearTimer() {
      if (this.$data.interval) clearInterval(this.$data.interval);
    },
    start() {
      this.$data.interval = setInterval(() => {
        this.$data.count--;
        if (this.$data.count === 3 && this.$parent.data.id === this.user.id)
          this.$plugins.audio.play("TurnReminder");
        if (this.$data.count <= 1) {
          this.clearTimer();
        }
      }, 1000);
    },
  },
  beforeDestroy() {
    this.clearTimer();
  },
  created() {
    this.start();
  },
};
</script>

<style lang="scss" scoped>
.seat-timer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  z-index: 2;
  cursor: pointer;

  &-inner {
    width: 65px;
    height: 65px;
    background-color: rgb(255, 255, 255);
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 48px;
    background-image: linear-gradient(
      90deg,
      #3b236d 0%,
      #7c4fcc 40%,
      #7c4fcc 60%,
      #3b236d 100%
    );
  }
}

.seat-me {
  .seat-timer {
    top: 3px;
    z-index: 4;
    .slice2 {
      transform: rotate(180deg) scale(1.7);
    }
    .slice1 {
      transform: scale(1.7);
    }
    .spark {
      width: 180px;
      height: 180px;
      left: 3px !important;
    }
  }
}

.avatar-glow {
  position: absolute;
  height: 150px;
  width: 150px;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  animation-name: seatGlow;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  z-index: 0;

  @keyframes seatGlow {
    0% {
      box-shadow: 0px 0 30px 2px rgba(255, 255, 255, 0);
    }
    50% {
      box-shadow: 0px 0 30px 2px rgba(255, 255, 255, 1);
    }
    to {
      box-shadow: 0px 0 30px 2px rgba(255, 255, 255, 0);
    }
  }
}

.slice1 {
  transform: scale(1.7);
}

.slice-wrap:before {
  content: " ";
  position: absolute;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  border: 2px solid rgba(107, 192, 47, 1);
  box-sizing: border-box;
  clip: rect(0, 70px, 140px, 0);
  background-color: rgba(107, 192, 47, 0.6);
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
}
.slice-wrap {
  position: absolute;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  clip: rect(0, 140px, 140px, 70px);
}
.slice2.slice-wrap {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg) scale(1.7);
}
.slice1:before {
  -webkit-animation: 9s spin linear forwards;
  -moz-animation: 9s spin linear forwards;
  -o-animation: 9s spin linear forwards;
  -ms-animation: 9s spin linear forwards;
  animation: 9s spin linear forwards;
}

.slice2:before {
  -webkit-transform: rotate(0.00001deg);
  -moz-transform: rotate(0.00001deg);
  -o-transform: rotate(0.00001deg);
  -ms-transform: rotate(0.00001deg);
  transform: rotate(0.00001deg);
  -webkit-animation: 4.5s spin2 linear 4.5s forwards;
  -moz-animation: 4.5s spin2 linear 4.5s forwards;
  -ms-animation: 4.5s spin2 linear 4.5s forwards;
  -o-animation: 4.5s spin2 linear 4.5s forwards;
  animation: 4.5s spin2 linear 4.5s forwards;
}

@keyframes spin {
  0% {
    transform: rotate(10deg);
    background: rgba(107, 192, 47, 0.8);
    border: 2px solid rgba(107, 192, 47, 1);
  }
  50% {
    transform: rotate(180deg);
    background: rgba(255, 255, 0, 0.8);
    border: 2px solid rgba(255, 255, 0, 1);
  }
  100% {
    transform: rotate(180deg);
    background: rgba(255, 0, 0, 0.8);
    border: 2px solid rgba(255, 0, 0, 1);
  }
}
@keyframes spin2 {
  from {
    transform: rotate(0deg);
    background: rgba(255, 255, 0, 0.8);
    border: 2px solid rgba(255, 255, 0, 1);
  }
  to {
    transform: rotate(180deg);
    background: rgba(255, 0, 0, 0.8);
    border: 2px solid rgba(255, 0, 0, 1);
  }
}

.spark {
  position: absolute;
  width: 280px;
  height: 280px;
  background: url("~@/assets/images/table/spark.png") no-repeat;

  background-position: center -2px;
  z-index: 1;
  top: -3px;
  left: -3px;
  animation: 9.5s spinspark linear forwards;
}

@keyframes spinspark {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
